import Icon from '@elements/Icon';
import { classNames } from '@helpers/classNames';
import PropTypes from 'prop-types';

const variants = {
  Actueel: {
    styles: {
      bg: 'bg-functional-completed',
      text: 'text-functional-contrast',
      icon: 'check',
    },
    text: 'Actueel',
  },
  Preview: {
    styles: {
      bg: 'bg-accent',
      text: 'text-functional-contrast',
      icon: 'check',
    },
    text: 'Preview',
  },
  ja: {
    styles: {
      bg: 'bg-functional-completed',
      text: 'text-functional-contrast',
      icon: 'check',
    },
    text: 'Ja',
  },
  nee: {
    styles: {
      bg: 'bg-functional-error',
      text: 'text-functional-contrast',
      icon: 'x',
    },
    text: 'Nee',
  },
  Archief: {
    styles: {
      bg: 'bg-accent',
      text: 'text-functional-contrast',
      icon: '',
    },
    text: 'Archief',
  },
  nieuw: {
    styles: {
      bg: 'bg-functional-completed',
      text: 'text-functional-contrast',
      icon: '',
    },
    text: 'Nieuw',
  },
  Actief: {
    styles: {
      bg: 'bg-functional-completed',
      text: 'text-functional-contrast',
      icon: 'check',
    },
    text: 'Actief',
  },
  isAdmin: {
    styles: {
      bg: 'bg-functional-completed',
      text: 'text-functional-contrast',
      icon: 'check',
    },
    text: 'Dit profiel is een admin',
  },
  isNotAdmin: {
    styles: {
      bg: 'bg-functional-error',
      text: 'text-functional-contrast',
      icon: 'x',
    },
    text: 'Geen Admin',
  },
  Inactief: {
    styles: {
      bg: 'bg-functional-error',
      text: 'text-functional-contrast',
      icon: 'x',
    },
    text: 'Inactief',
  },
  Vervallen: {
    styles: {
      bg: 'bg-functional-error',
      text: 'text-functional-contrast',
      icon: 'x',
    },
    text: 'Vervallen',
  },
};

const Status = ({ variant }) => {
  if (!variant) {
    return;
  }
  const style = variants[variant].styles;
  const text = variants[variant].text;
  return (
    <div
      className={classNames(
        'flex h-11 w-fit justify-center gap-2 rounded-md px-4',
        style.bg,
        style.text,
      )}
    >
      {variants[variant].styles.icon && (
        <div className="m-auto">
          <Icon iconName={style.icon} color={style.text} />
        </div>
      )}

      <p className="m-auto font-secondary text-sm font-black">{text}</p>
    </div>
  );
};

Status.propTypes = {
  variant: PropTypes.string.isRequired,
};

Status.defaultProps = {
  variant: '',
};

export default Status;
